<template>
  <main class="outDoor">
    <div class="outDoor__banner">
      <div class="outDoor__bannerImgContainer container">
        <img
          class="outDoor__bannerImg"
          src="@/assets/image/Outdoor/Triomphe.png"
          alt=""
        />
      </div>
    </div>
    <section class="outDoor__section container">
      <OutdoorCard
        v-for="(outdoor, index) in outdoorLists"
        :key="outdoor.title"
        :item="outdoor"
        :index="+index"
        :itemLength="outdoorLists.length"
      />

      <div class="outDoor__bg"></div>
    </section>
  </main>
</template>

<script>
import OutdoorCard from '@/components/Outdoor/OutdoorCard.vue'

// API
import { apiGetOutDoor } from '@/api/webAPI'

export default {
  components: {
    OutdoorCard
  },
  data() {
    return {
      outdoorLists: []
    }
  },
  async created() {
    const outdoorResponse = await apiGetOutDoor()
    const outdoorLists = outdoorResponse.data.data

    this.outdoorLists = outdoorLists
  }
}
</script>

<style lang="scss">
.outDoor {
  &__bg,
  &__banner {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__banner {
    background-image: url('~@/assets/image/common/GrayBanner.png');
    height: 18.3rem;
    position: relative;
  }

  &__section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -16rem;
    padding-bottom: 10rem;
  }

  &__bannerImgContainer {
    position: relative;
    height: 100%;
  }

  &__bannerImg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 32.25rem;
    height: 11.4rem;
  }

  &__bg {
    background-image: url('~@/assets/image/Outdoor/OutdoorBg.jpg');
    width: 100%;
    padding-bottom: 7.9%;
  }
}

@media (max-width: 768px) {
  .outDoor {
    &__banner {
      height: 10rem;
    }

    &__bannerImg {
      bottom: 0;
      left: 0;
      width: 20.6rem;
      height: 7.3rem;
    }

    &__section {
      margin-top: 0;
    }

    &__bg {
      background-image: url('~@/assets/image/Outdoor/OutdoorBgMobile.jpg');
      padding-bottom: 19.7%;
    }
  }
}
</style>
