<template>
  <div class="outdoorCard" :class="classes">
    <div class="outdoorCard__body clickable ">
      <a  :title="`${item.title}(另開新視窗)`" :href="item.link" target="_blank" rel="noreferrer noopener">
        <h2 class="outdoorCard__title">{{ item.title }}</h2>
        <div
          class="outdoorCard__img"
          :style="`background-image: url(${item.image})`"
        >
          <img
            class="outdoorCard__360"
            src="@/assets/image/MiningLandscape/360.png"
          />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemLength: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    item: {
      title: {
        type: String,
        required: true
      },
      image: {
        type: String,
        required: true
      },
      link: {
        type: String,
        required: true
      }
    }
  },
  computed: {
    classes() {
      if (this.itemLength % 2 !== 0 && this.index === this.itemLength - 1) {
        return 'outdoorCard--last'
      }
      if (this.index % 2 === 0) return 'outdoorCard--left'
      return 'outdoorCard--right'
    }
  }
}
</script>

<style lang="scss">
.outdoorCard {
  width: 50%;
  padding: 5rem 0;
  z-index: 1;

  &__title::before {
    content: '';
    background-image: url('~@/assets/image/TitleIcon.png');
    width: 2.8rem;
    height: 1.9rem;
    display: inline-block;
    background-size: contain;
    background-position: center;
    margin-right: 0.6rem;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 124%;
    color: #595757;
    text-align: center;
    margin-bottom: 2.3rem;
  }

  &__img {
    padding-bottom: 75%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
  }

  &__360 {
    width: 12rem;
    height: 3.9rem;
    position: absolute;
    object-fit: contain;
    top: calc(100% - 1.95rem);
    left: 50%;
    transform: translateX(-50%);
  }
}

.outdoorCard--left {
  padding-left: 15rem;
  padding-right: 7rem;
}

.outdoorCard--right {
  padding-left: 7rem;
  padding-right: 15rem;
}

.outdoorCard--last {
  padding-left: 11rem;
  padding-right: 11rem;
}

@media (max-width: 768px) {
  .outdoorCard {
    width: 100%;
  }

  .outdoorCard--left,
  .outdoorCard--right,
  .outdoorCard--last {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
